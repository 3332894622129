<!--
 * @Author: lbh
 * @Date: 2021-09-23 15:02:39
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-26 16:14:43
 * @Description: 餐廳案例
-->
<template>
  <div
    class="restaurant-case"
    :class="window.restaurant_class"
  >
    <!-- 紅線 -->
    <div class="line theme_bg_color"></div>
    <!-- 標題 -->
    <h2 class="title">{{ $t('restaurantCase["bigTitle"]') }}</h2>
    <div class="items">
      <div
        class="item"
        v-for="(item,index) in restaurantCase"
        :key="index"
      >
        <img :src="item.img" />
        <h2
          class="theme_color"
          v-text="item.title"
        ></h2>
        <div
          class="content"
          v-if="item.content"
        >
          <strong>[{{$t('restaurantCase["Solving Cases"]')}}] </strong>
          <span v-text="item.content"></span>

        </div>
        <sButton
          type="duck"
          :index="index"
          :text="$t('restaurantCase[\'Further understanding\']')"
          :go="item.go"
        />
      </div>
    </div>
  </div>
</template>

<script>
import sButton from "@/components/commo/button";
export default {
  name: "restaurant-case",
  components: {
    sButton
  },
  props: {
    restaurantCase: {
      default () {
        return []
      }
    },
    windowW: {
      default () {
        return 1000;
      }
    },
  },
  watch: {
    windowW () {
      this.initWindow();
    }
  },
  data () {
    return {
      window: {
        restaurant_class: ""
      }
    }
  },
  methods: {
    initWindow () {
      let w = this.windowW;
      if (w < 700) {
        this.window.restaurant_class = 'restaurant-case_2'
      } else if (w < 1000) {
        this.window.restaurant_class = 'restaurant-case_1'
      } else {
        this.window.restaurant_class = ''
      }
    },
  }
}
</script>

<style lang="less" scoped>
.restaurant-case_1 {
  padding: 50px 20% !important;
  .items {
    .item {
      width: 100% !important;
    }
  }
}
.restaurant-case_2 {
  padding: 50px 20px !important;
  .items {
    .item {
      width: 100% !important;
    }
  }
}
.restaurant-case {
  .line {
    width: 20%;
    margin: 25px auto;
    height: 3px;
  }
  .title {
    text-align: center;
    font-size: 36px;
    margin-bottom: 30px;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    img {
      width: 100%;
    }
    .item {
      margin: 0 10px;
      background-color: #fff;
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      h2 {
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        margin-bottom: 20px;
      }
      .content {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .restaurant-case {
    padding: 10px;
    .items {
      .item {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .restaurant-case {
    padding: 50px 10%;
    .items {
      .item {
        width: calc(33% - 60px);
      }
    }
  }
}
</style>
